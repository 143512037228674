import { Grid, Link } from '@material-ui/core';

import LinkedInIcon from '../../../assets/images/icons/linkedin.svg';
import React from 'react';
import { useHistory } from 'react-router-dom';
import useStyles from '../styles';

const SocialSection = () => {
  const classes = useStyles();
  const history = useHistory();

  const navigate = (event, route) => {
    event.preventDefault();
    history.push(route);
  };

  return (
    <Grid container className={classes.socialSectionContainer}>
      <Grid item xs={4} sm={12}>
        <Link
          href="https://www.linkedin.com/company/forged-concepts"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={LinkedInIcon}
            className={classes.socialIcon}
            alt="linkedin"
          />
        </Link>
      </Grid>
      <Grid item xs={8} sm={12} className={classes.copyrightContainer}>
        <Link
          className={classes.navbarLink}
          href="#"
          onClick={(e) => navigate(e, '/')}
        >
          FORGED CONCEPTS{' '}
          <span
            className={classes.logoTMText}
            dangerouslySetInnerHTML={{ __html: '&reg;' }}
          />
        </Link>
      </Grid>
    </Grid>
  );
};

export default SocialSection;
