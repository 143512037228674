import { makeStyles } from "@material-ui/core";
import BannerBg from "../../assets/images/background/banner_bg.png";

export default makeStyles((theme) => ({
  bannerSectionContainer: {
    backgroundColor: "rgba(0,0,0,0.5)",
    backgroundImage: `url(${BannerBg})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    filter: "grayscale(100%)",
    [theme.breakpoints.up("md")]: {
      minHeight: "30em",
    },
  },
  bannerSectionHeadingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  bannerSectionHeading: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      color: "#fff",
      fontSize: "5em",
    },
    [theme.breakpoints.down("xs")]: {
      color: "#000",
      fontSize: "5em",
    },
  },
  bannerSectionLinksContainer: {
    display: "flex",
    alignItems: "start",
    justifyContent: "center",
  },
  bannerSectionLinksContainerHolder: {
    height: "90%",
  },
  bannerSectionServicesContainer: {
    backgroundColor: "rgba(0,0,0,0.75)",
    padding: "0 2em 2em 2em",
    marginBottom: "1em",
  },
  bannerSectionLinkContainer: {
    borderLeft: "2px solid #E6E6E6",
    display: "flex",
    alignItems: "start",
    flexDirection: "column",

    justifyContent: "flex-end",
    marginTop: "auto",
    [theme.breakpoints.up("md")]: {
      fontSize: "1.2em",
      height: "90%",
    },
  },
  bannerSectionLinkContainerItem: {
    alignItems: "center",
    display: "flex",
    marginTop: "1em",
  },
  bannerSectionLinkPopArrow: {},
  bannerSectionLink: {
    color: "#fff",
    fontSize: "1.5em",
    marginLeft: ".5em",
  },
}));
