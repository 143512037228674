import React from "react";
import { useHistory } from "react-router-dom";
import { Grid, Link } from "@material-ui/core";

import routes from "../../../utils/Routes";
import useStyles from "../styles";

const OurServicesSection = () => {
  const classes = useStyles();
  const history = useHistory();

  const navigate = (event, route) => {
    event.preventDefault();
    history.push(route);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Link href="#" onClick={(e) => navigate(e, "/services")}>
          <h2 className={classes.sectionHeading}>SERVICES</h2>
        </Link>
      </Grid>
      <Grid item xs={12}>
        <ul className={classes.navbarHorizontal}>
          {routes
            .filter((route) => route.isService)
            .map((route) => (
              <li className={classes.navbarItem} key={route.name}>
                <Link
                  className={classes.navbarLink}
                  href="#"
                  onClick={(e) => navigate(e, route.path)}
                >
                  {route.name.toUpperCase()}
                </Link>
              </li>
            ))}
        </ul>
      </Grid>
    </Grid>
  );
};

export default OurServicesSection;
