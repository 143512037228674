import React from "react";
import Grid from "@material-ui/core/Grid";
import { useForm, ValidationError } from "@formspree/react";
import { useHistory } from "react-router-dom";
import { Link } from "@material-ui/core";

import useStyles from "./styles";

const ContactForm = () => {
  const classes = useStyles();
  const history = useHistory();

  const navigate = (event, route) => {
    event.preventDefault();
    history.push(route);
  };

  const [state, handleSubmit] = useForm("xeqveknb");

  if (state.succeeded) {
    return (
      <h2 className={classes.contactFormMessage}>
        Thank you for reaching out to us. We will be in touch soon!
      </h2>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid
        id="contact_us"
        container
        direction="column"
        className={classes.contactFormContainer}
      >
        <Grid item xs={12}>
          <h1 className={classes.contactFormHeading}>CONTACT US</h1>
        </Grid>
        <Grid item xs={12}>
          <h2 className={classes.contactFormSubHeading}>
            You’ve got a great idea and we want to help you make it real...
          </h2>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <div className={classes.textFieldContainer}>
                <label htmlFor="first-name" className={classes.textFieldLabel}>
                  First Name
                </label>
                <input
                  type="text"
                  id="first-name"
                  name="first-name"
                  className={classes.textFieldInput}
                  required
                />
              </div>
              <ValidationError
                prefix="First Name"
                field="first-name"
                errors={state.errors}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <div className={classes.textFieldContainer}>
                <label htmlFor="last-name" className={classes.textFieldLabel}>
                  Last Name
                </label>
                <input
                  type="text"
                  id="last-name"
                  name="last-name"
                  className={classes.textFieldInput}
                  required
                />
              </div>
              <ValidationError
                prefix="Last Name"
                field="last-name"
                errors={state.errors}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <div className={classes.textFieldContainer}>
                <label htmlFor="email" className={classes.textFieldLabel}>
                  Email
                </label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  className={classes.textFieldInput}
                  required
                />
              </div>
              <ValidationError
                prefix="Email"
                field="email"
                errors={state.errors}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <div className={classes.textFieldContainer}>
                <label htmlFor="company" className={classes.textFieldLabel}>
                  Company
                </label>
                <input
                  type="text"
                  id="company"
                  name="company"
                  className={classes.textFieldInput}
                  required
                />
              </div>
              <ValidationError
                prefix="Company"
                field="company"
                errors={state.errors}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <div className={classes.textFieldContainer}>
                <label htmlFor="job-title" className={classes.textFieldLabel}>
                  Job Title
                </label>
                <input
                  type="text"
                  id="job-title"
                  name="job-title"
                  className={classes.textFieldInput}
                  required
                />
              </div>
              <ValidationError
                prefix="Job Title"
                field="job-title"
                errors={state.errors}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <div className={classes.textAreaContainer}>
                <label
                  htmlFor="how-can-we-help"
                  className={classes.textAreaLabel}
                >
                  How Can We Help You?
                </label>
                <textarea
                  id="how-can-we-help"
                  name="how-can-we-help"
                  className={classes.textAreaTextArea}
                  required
                />
              </div>
              <ValidationError
                prefix="How Can We Help"
                field="how-can-we-help"
                errors={state.errors}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.agreementCommContainer}>
              <h2 className={classes.contactFormSubHeading}>
                I agree to receive communications from Forged Concepts.*
              </h2>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.checkBoxContainer}>
              <input
                type="checkbox"
                id="agree-to-terms"
                name="agree-to-terms"
                className={classes.checkBoxCheckBox}
                required
              />
              <label htmlFor="agree-to-terms" className={classes.checkBoxLabel}>
                Your privacy is important to us. Submitting this form allows us
                to contact you with the information you provided, detailed in
                our [
                <Link
                  className={classes.bannerSectionLink}
                  href="#"
                  onClick={(e) => navigate(e, "/privacy-policy")}
                >
                  privacy policy
                </Link>
                ]. <br />
                *We won't sell or share your data with anyone else.
              </label>
            </div>
            <ValidationError
              prefix="Agree To Terms"
              field="agree-to-terms"
              errors={state.errors}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <div
              className="g-recaptcha"
              data-sitekey="6Lcn-MEaAAAAAN6XoNY7EhwVSsKNvnF_0PmA9Bub"
            ></div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.buttonContainer}>
              <button
                type="submit"
                disabled={state.submitting}
                className={classes.contactUsButton}
              >
                Send
              </button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default ContactForm;
