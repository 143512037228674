import React from "react";
import { Grid } from "@material-ui/core";

import BannerSection from "./Components/BannerSection";
import ContactForm from "../../components/Sections/ContactForm";
import Divider from "../../components/Sections/Divider";
import HomeNotesSection from "../../components/Sections/HomeNotes";
import ScreenReviewSection from "../../components/Sections/ScreenReview";

const Home = () => {
  return (
    <Grid container direction="column">
      <BannerSection />
      <Divider />
      <ScreenReviewSection />
      <Divider />
      <HomeNotesSection />
      <Divider />
      <ContactForm />
      <Divider />
    </Grid>
  );
};

export default Home;
