import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  contentContainer: {
    width: "100%",
    height: "100%",
  },
  container: {
    height: '75vh',
    flexDirection: 'row',
    marginBottom: '3px',
  },
  containerSection: {
    width: '100%',
    height: '100%',
  },
  bannerColumn: {
    width: '50%',
    height: '100%',
  },
  appModIcon: {
    width: 40,
    height: 40,
    padding: 8,
  },
  bannerIcon: {
    width: 75,
    height: 75,
    marginBottom: 25,
  },
  sectionTitleText: {
    fontSize: '3em',
    color: 'white',
    width: '50%',
    textAlign: 'center',
  },
  detailsContainer: {
    height: 'auto'
  },
  detailsRows: {
    backgroundColor: '#e9f8f1ff',
    height: 'auto',
    padding: '0em 7em',
    marginBottom: '3px',
    [theme.breakpoints.down("sm")]: {
      padding: '0em 2em',
    },
  },
  detailsMiddleRow: {
    padding: '0em',
  },
  detailsColumn: {
    width: '50%',
    height: '100%',
    padding: '2em 0em',
  },
  columnTopDivider: {
    [theme.breakpoints.down("sm")]: {
      borderTop: '3px solid white'
    },
  },
  detailsLastRow: {
    padding: '2em 0em',
    margin: '0em',
  },
  detailsHeaderTitleText: {
    padding: '0.5em 0em',
    fontSize: '1.75em',
    color: 'grey',
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      fontSize: '1.5em',
    },
  },
  firstRowHeaderTitleText: {
    padding: '2.5em 0em',
  },
  detailsText: {
    padding: '3em',
    margin: 0,
    fontSize: '1.1em',
    width: '75%',
    [theme.breakpoints.down("sm")]: {
      padding: '1.5em',
      fontSize: '0.85em',
    },
  }
}));
