import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    width: "25vw",
    backgroundColor: "black",
    color: "white",
    [theme.breakpoints.down('md')]: {
      width: '50vw',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
    },
  },
  crossButton: {
    width: '30px',
    height: '30px',
    alignSelf: 'flex-end',
    color: 'white'
  },
  menuItemsContainer: {
    margin: "2em 0em",
    height: "100%",
  },
  menuItem: {
    margin: "1em 1.5em",
    textAlign: "left",
    fontSize: "1.5em",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
    [theme.breakpoints.down('md')]: {
      fontSize: "1.5em",
      margin: "0.95em 1.5em",
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: "1.5em",
      margin: "0.9em 1.5em",
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: "1.25em",
      margin: "0.85em 1.5em",
    },
  },
}));
