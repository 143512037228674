import React from "react";
import Grid from "@material-ui/core/Grid";

import BannerSection from "../../components/Sections/Banner";
import DetailsSection from "./Components/DetailsSection";
import CloudServicesIcon from "../../assets/images/icons/share.svg";
import CloudServicesBg from "../../assets/images/background/cloudservices_bg.png";

const ManagedCloudServices = () => {
  const bannerStyle = {
    backgroundImage: `url(${CloudServicesBg})`,
  };

  const backgroundStyle = {
    backgroundColor: "rgba(9.4,34.8,82.6,0.5)",
  }

  return (
    <Grid container direction="column">
      <BannerSection
        appModIcon={CloudServicesIcon}
        backgroundStyle={backgroundStyle}
        bannerStyle={bannerStyle}
        title="Managed Cloud Services"
        titlePosition="flex-end"
      />
      <DetailsSection />
    </Grid>
  );
};

export default ManagedCloudServices;
