import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down("xl")]: {
      padding: "2em 4em",
    },
    [theme.breakpoints.down("md")]: {
      padding: "2em",
    },
  },
  logoContainer: {
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
  },
  logo: {
    maxHeight: "8em",
  },
  logoTextContainer: {
    marginLeft: ".5em",
  },
  logoForgedText: {
    display: "block",
    fontSize: "2.0em",
    letterSpacing: "0.05em",
    margin: 0,
    height: "1em",
  },
  logoConceptsText: {
    color: "grey",
    display: "inline",
    fontSize: "1.6em",
    letterSpacing: ".1em",
    margin: 0,
  },
  logoTMText: {
    color: "grey",
    display: "inline",
    fontSize: ".8em",
    letterSpacing: ".1em",
    margin: 0,
  },
  navbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    listStyleType: "none",
    padding: 0,
  },
  navbarItem: {
    fontSize: "1.5em",
    margin: "0 .3em",
  },
  navBarItemIcon: {
    marginLeft: "1em",
  },
  navbarDivider: {
    color: "#000",
    marginTop: 0,
  },
  navbarButton: {
    padding: 0,
  },
  navbarButtonInner: {
    fontSize: "2em",
  },
}));
