import { makeStyles } from "@material-ui/core";
import BannerBg from "../../assets/images/background/contactus_bg.png";
import PPBg from "../../assets/images/background/network.png";

export default makeStyles(() => ({
  contentContainer: {
    width: "100%",
    height: "100%",
    "& p": {
      textShadow: "0px 0px 0.5px black;",
      lineHeight: "1.4em",
      fontSize: "1.2em",
    },
  },
  servicesBackground: {
    backgroundImage: `url(${PPBg})`,
    filter: "grayscale(100%)",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  bannerContainer: {
    height: "45vh",
  },
  sectionTitleText: {
    fontSize: "3em",
    color: "white",
    width: "35%",
    textAlign: "center",
  },
  detailsContainer: {
    height: "auto",
    backgroundImage: `url(${BannerBg})`,
  },
  detailsSectionOverlay: {
    height: "100%",
    backgroundColor: "rgba(255,255,255,0.8)",
  },
  detailsRow: {
    padding: "2em 5em",
    marginBottom: "20em",
  },
  rowHeader: {
    margin: 0,
    fontSize: "2.2em",
    color: "#6d6e71ff",
  },
  rowText: {
    margin: 0,
  },
}));
