import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "./App.css";
import routes from "./utils/Routes";
import ScrollToTop from "./components/ScrollToTop";
import { LayoutProvider } from "./context/layoutContext";
import NotFound404 from "./pages/NotFound404";

function App() {
  return (
    <Router>
      <LayoutProvider>
        <ScrollToTop />
        <Switch>
          {routes.map((route) => (
            <Route exact path={route.path} key={route.name}>
              {route.component}
            </Route>
          ))}
          <Route path="*" component={NotFound404} />
        </Switch>
      </LayoutProvider>
    </Router>
  );
}

export default App;
