import { makeStyles } from "@material-ui/core";
import FooterBg from "../../assets/images/background/footer_bg.png";

export default makeStyles((theme) => ({
  container: {
    backgroundImage: `url(${FooterBg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
  },
  overlayContainer: {
    backgroundColor: "rgba(0,0,0,0.7)",
  },
  sectionContainer: {
    [theme.breakpoints.up("sm")]: {
      padding: 0,
    },
    [theme.breakpoints.down("xs")]: {
      padding: "1em",
    },
  },
  navbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    listStyleType: "none",
    padding: 0,
  },
  navbarHorizontal: {
    alignItems: "center",
    justifyContent: "center",
    listStyleType: "none",
    padding: 0,
  },
  navbarItem: {
    [theme.breakpoints.up("md")]: {
      fontSize: "1.5em",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1em",
    },
    margin: "0 .3em",
  },
  navbarLink: {
    color: "#fff",
  },
  navbarDivider: {
    color: "#fff",
    marginTop: 0,
  },
  sdvobBadge: {
    maxWidth: "100%",
  },
  sectionHeading: {
    color: "#A7A9AC",
    [theme.breakpoints.up("md")]: {
      fontSize: "1.5em",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1em",
    },
    marginBottom: 0,
    marginTop: ".65em",
  },
  socialSectionContainer: {
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      paddingTop: "1.4em",
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: 0,
    },
  },
  socialIcon: {
    maxWidth: "2em",
  },
  copyrightContainer: {
    alignItems: "flex-end",
    color: "#fff",
    display: "flex",
    justifyContent: "flex-end",
    paddingBottom: "1em",
    paddingRight: "1em",
    position: "relative",
  },
  logoTMText: {
    color: "grey",
    display: "inline",
    fontSize: ".8em",
    letterSpacing: ".1em",
    margin: 0,
  },
  contactUsLinkText: {
    fontSize: "1em",
    margin: 0,
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
}));
