import React from "react";
import Grid from "@material-ui/core/Grid";
import BannerSection from "./Components/BannerSection";
import DetailsSection from "./Components/DetailsSection";

const NotFound404 = () => {
  return (
    <Grid container direction="column">
      <BannerSection />
      <DetailsSection />
    </Grid>
  );
};

export default NotFound404;
