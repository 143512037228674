import React from "react";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import useStyles from "../styles";

const BannerSection = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      container
      justify={matches ? "center" : "flex-start"}
      alignItems="center"
      className={`${classes.servicesBackground} ${classes.bannerContainer}`}
    >
      <h2 className={classes.sectionTitleText}>PAGE NOT FOUND</h2>
    </Grid>
  );
};

export default BannerSection;
