import React from "react";
import Grid from "@material-ui/core/Grid";
import useStyles from "../styles";

const DetailsSection = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="column"
      className={`${classes.section} ${classes.detailsContainer}`}
    >
      <Grid
        container
        direction="column"
        justify="center"
        className={classes.detailsSectionOverlay}
      >
        <Grid container direction="column" className={classes.detailsRow}>
          <h2 className={classes.rowHeader}>404</h2>
          <p className={classes.rowText}>
            It seems you are looking for a page, but it does not exist here!
          </p>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DetailsSection;
