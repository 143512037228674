import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  homeNotesSection: {
    backgroundColor: "#BCBEC0",
    fontSize: "1.2em",
    [theme.breakpoints.down("xs")]: {
      padding: "2em",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "4em",
    },
  },
}));
