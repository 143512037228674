import Home from "../pages/Home";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import CloudAppDev from "../pages/CloudAppDev";
import ManagedCloudServices from "../pages/ManagedCloudServices";
import ApplicationModernization from "../pages/ApplicationModernization";
import DevOps from "../pages/DevOps";
import Services from "../pages/Services";

import AppModIcon from "../assets/images/icons/mouse.svg";
import CloudAppDevIcon from "../assets/images/icons/big-light.svg";
import CloudServicesIcon from "../assets/images/icons/share.svg";
import DevOpsIcon from "../assets/images/icons/devops_icon.svg";

import DevOpsBg from "../assets/images/background/devops_bg.png";
import AppModBg from "../assets/images/background/appmod_bg.png";
import CloudServicesBg from "../assets/images/background/cloudservices_bg.png";
import CloudAppDevBg from "../assets/images/background/cloudappdev_bg.png";

const Routes = [
  {
    path: "/",
    name: "Home",
    component: <Home />,
  },
  {
    path: "/services",
    name: "Services",
    component: <Services />,
  },
  {
    path: "/services/devops",
    name: "DevOps",
    component: <DevOps />,
    icon: DevOpsIcon,
    background: DevOpsBg,
    backgroundColor: "rgba(68.4,6.0,69.1,0.5)",
    isService: true,
    position: "end",
  },
  {
    path: "/services/application-modernization",
    name: "Application Modernization",
    component: <ApplicationModernization />,
    icon: AppModIcon,
    background: AppModBg,
    backgroundColor: "rgba(9.1,57.2,35.1,0.5)",
    isService: true,
    position: "start",
  },
  {
    path: "/services/managed-cloud-services",
    name: "Managed Cloud Services",
    component: <ManagedCloudServices />,
    icon: CloudServicesIcon,
    background: CloudServicesBg,
    backgroundColor: "rgba(9.4,34.8,82.6,0.5)",
    isService: true,
    position: "end",
  },
  {
    path: "/services/cloud-application-development",
    name: "Cloud Application Development",
    component: <CloudAppDev />,
    icon: CloudAppDevIcon,
    background: CloudAppDevBg,
    backgroundColor: "rgba(94.9,65.0,1.9,0.5)",
    isService: true,
    position: "start",
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: <PrivacyPolicy />,
  },
];

export default Routes;
