import React from "react";
import Grid from "@material-ui/core/Grid";
import ListIcon from "../../../assets/images/icons/list.svg";
import WrenchIcon from "../../../assets/images/icons/wrench-icon.svg";
import HeadphoneIcon from "../../../assets/images/icons/headphones.svg";
import useStyles from "../styles";

const DetailsSection = () => {
  const classes = useStyles();
  return (
    <Grid className={classes.detailsContainer} direction="column" container>
      <Grid
        className={classes.detailsRows}
        container
        direction="row"
        alignItems="center"
        justify="flex-start"
      >
        <h2 className={classes.tagHeaderText}>How We Manage Cloud Services</h2>
      </Grid>
      <Grid
        className={`${classes.detailsRows} ${classes.detailsMiddleRow}`}
        container
        direction="row"
      >
        <Grid
          item
          className={classes.detailsColumn}
          container
          direction="column"
          alignItems="center"
          justify="flex-start"
          xs={12}
          md={6}
        >
          <img
            className={classes.appModIcon}
            src={ListIcon}
            alt="Cloud Infrastructure"
          />
          <h2 className={classes.detailsHeaderTitleText}>
            Cloud Infrastructure
          </h2>
          <p className={classes.detailsText}>
            {">"} Managing your cloud infrastructure
          </p>
        </Grid>
        <Grid
          item
          className={`${classes.detailsColumn} ${classes.columnTopDivider}`}
          container
          direction="column"
          alignItems="center"
          justify="flex-start"
          xs={12}
          md={6}
        >
          <img className={classes.appModIcon} src={WrenchIcon} alt="DevOps" />
          <h2 className={classes.detailsHeaderTitleText}>Monitoring tools</h2>
          <p className={classes.detailsText}>
            {">"} Managing infrastructure can be costly, but we can help reduce
            cost and free up resources. Forged Concepts utilizes cloud services
            to manage your infrastructure so that you can focus on your
            business.
          </p>
        </Grid>
      </Grid>
      <Grid
        className={`${classes.detailsRows} ${classes.detailsLastRow}`}
        container
        direction="column"
        alignItems="center"
        justify="center"
      >
        <img
          className={classes.appModIcon}
          src={HeadphoneIcon}
          alt="Cloud Infrastructure"
        />
        <h2 className={classes.detailsHeaderTitleText}>Incident handling</h2>
        <p className={classes.detailsText}>
          {">"} Forged Concepts provides 24/7 monitoring and incident handling,
          so issues are resolved with minimal downtime.
        </p>
      </Grid>
    </Grid>
  );
};

export default DetailsSection;
