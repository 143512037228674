import React from "react";
import Grid from "@material-ui/core/Grid";

import BannerSection from "../../components/Sections/Banner";
import NotesSection from "./Components/NotesSection";
import CloudAppDevIcon from '../../assets/images/icons/big-light.svg';
import CloudAppDevBg from "../../assets/images/background/cloudappdev_bg.png";

const CloudAppDev = () => {
  const bannerStyle = {
    backgroundImage: `url(${CloudAppDevBg})`,
  };

  const backgroundStyle = {
    backgroundColor: "rgba(94.9,65.0,1.9,0.5)",
  }

  return (
    <Grid container direction="column">
      <BannerSection
        appModIcon={CloudAppDevIcon}
        backgroundStyle={backgroundStyle}
        bannerStyle={bannerStyle}
        title="Cloud Application Development"
        titlePosition="flex-start"
      />
      <NotesSection />
    </Grid>
  );
};

export default CloudAppDev;
