import React from "react";
import Grid from "@material-ui/core/Grid";

import BannerSection from "../../components/Sections/Banner";
import DetailsSection from "./Components/DetailsSection";
import AppModIcon from "../../assets/images/icons/mouse.svg";
import AppModBg from "../../assets/images/background/appmod_bg.png";

const ApplicationModernization = () => {
  const bannerStyle = {
    backgroundImage: `url(${AppModBg})`,
  };

  const backgroundStyle = {
    backgroundColor: "rgba(9.1,57.2,35.1,0.5)",
  }

  return (
    <Grid container direction="column">
      <BannerSection
        appModIcon={AppModIcon}
        backgroundStyle={backgroundStyle}
        bannerStyle={bannerStyle}
        title="Application Modernization"
        titlePosition="flex-start"
      />
      <DetailsSection />
    </Grid>
  );
};

export default ApplicationModernization;
