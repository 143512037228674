import React from "react";
import { useHistory } from "react-router-dom";
import { Grid, Link } from "@material-ui/core";
import { NavHashLink } from "react-router-hash-link";
import SdvobBadge from "../../../assets/images/sdvob-badge.png";
import useStyles from "../styles";

const AboutUsSection = () => {
  const classes = useStyles();
  const history = useHistory();

  const navigate = (event, route) => {
    event.preventDefault();
    history.push(route);
  };

  const navBarLink = {
    textDecoration: "none",
    color: "white",
  };

  const contactUsLink = {
    fontWeight: "normal",
    textDecoration: "none",
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12}>
        <ul className={classes.navbar}>
          <li className={classes.navbarItem}>
            <NavHashLink
              smooth
              to="/#contact_us"
              exact={true}
              style={navBarLink}
              activeStyle={contactUsLink}
            >
              <h6 className={classes.contactUsLinkText}>CONTACT US</h6>
            </NavHashLink>
          </li>
          <li className={`${classes.navbarDivider} ${classes.navbarItem}`}>
            |
          </li>
          <li className={classes.navbarItem}>
            <Link
              className={classes.navbarLink}
              href="#"
              onClick={(e) => navigate(e, "/privacy-policy")}
            >
              PRIVACY POLICY
            </Link>
          </li>
        </ul>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={4}>
            <img
              src={SdvobBadge}
              className={classes.sdvobBadge}
              alt="NVBDC Certified Service Disabled Veteran Owned Business"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AboutUsSection;
