import React from "react";
import Grid from "@material-ui/core/Grid";
import PopArrow from "../../../assets/images/icons/pop_arrow.svg";
import useStyles from "../styles";

const NotesSection = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="column"
      alignItems="flex-start"
      justify="flex-start"
      className={`${classes.section} ${classes.notesSection}`}
    >
      <div className={classes.headerContainer}>
        <img
          src={PopArrow}
          width={30}
          height={30}
          alt="Cloud Application Development"
        />
        <h2 className={classes.notesSubHeadingText}>
          Cloud Application Development
        </h2>
      </div>
      <Grid container>
        <p className={classes.note}>
          {">"} If you have a great idea, but you need some assistance with
          developing it, Forged Concepts is here to help. We are cloud experts,
          and we know how to build applications for the cloud. We also help
          build applications that are event-driven, so you don’t have to pay for
          idle time, but can still scale as your application grows.
        </p>
      </Grid>
    </Grid>
  );
};

export default NotesSection;
