import React from "react";
import { useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useStyles from "./styles";

const BannerSection = ({
  appModIcon,
  backgroundStyle,
  bannerStyle,
  titlePosition = "flex-start",
  title = "",
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      className={`${classes.container} ${classes.background}`}
      style={bannerStyle}
      container
      direction="row"
      justify="flex-start"
      alignItems="center"
    >
      <Grid
        className={`${classes.containerSection}`}
        style={backgroundStyle}
        justify="center"
        alignItems={matches ? "center" : titlePosition}
        direction="column"
        container
      >
        <Grid
          className={classes.bannerColumn}
          justify="center"
          alignItems="center"
          direction="column"
          container
        >
          <img
            className={`${classes.appModIcon} ${classes.bannerIcon}`}
            src={appModIcon}
            alt="DevOps"
          />
          <h2 className={classes.sectionTitleText}>{title}</h2>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BannerSection;
