import React from "react";
import Grid from "@material-ui/core/Grid";
import BannerSection from "../../components/Sections/Banner";
import DetailsSection from "./Components/DetailsSection";
import DevOpsBg from "../../assets/images/background/devops_bg.png";
import DevOpsIcon from '../../assets/images/icons/devops_icon.svg';

const DevOps = () => {
  const bannerStyle = {
    backgroundImage: `url(${DevOpsBg})`,
  };

  const backgroundStyle = {
    backgroundColor: 'rgba(68.4,6.0,69.1,0.5)',
  };

  return (
    <Grid container direction="column">
      <BannerSection
        appModIcon={DevOpsIcon}
        backgroundStyle={backgroundStyle}
        bannerStyle={bannerStyle}
        title="DevOps"
        titlePosition="flex-end"
      />
      <DetailsSection />
    </Grid>
  );
};

export default DevOps;
