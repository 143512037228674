import { makeStyles } from "@material-ui/core";
import ScreenReview from "../../../assets/images/background/footer_bg.png";

export default makeStyles((theme) => ({
  screenReviewSection: {
    backgroundImage: `url(${ScreenReview})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    minHeight: "40em",
  },
}));
