import React from "react";
import Grid from "@material-ui/core/Grid";
import useStyles from "../styles";

const DetailsSection = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="column"
      className={`${classes.section} ${classes.detailsContainer}`}
    >
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.detailsSectionOverlay}
      >
        <Grid container item xs={11}>
          <h1 class={classes.detailsHeading}>
            Privacy Policy for Forged Concepts
          </h1>
          <span class={classes.detailsSubHeading}>
            Last Update: This Privacy Notice was last updated on May 1, 2021.
          </span>

          <p class={classes.detailsParagraph}>
            At Forged Concepts, accessible from https://forged-concepts.com, we
            respect the privacy of our visitors. This Privacy Policy document
            contains types of information that is collected and recorded by
            Forged Concepts and how we use it.
          </p>

          <p class={classes.detailsParagraph}>
            If you have additional questions or require more information about
            our Privacy Policy, do not hesitate to contact us.
          </p>

          <h2>About Us</h2>

          <p class={classes.detailsParagraph}>
            Forged Concepts manages services for public cloud platforms,
            hosting, software development, and other cloud based solutions and
            consulting services to businesses. Our website provides visitors
            information about us and our services.
          </p>

          <h2>Information That You Provide</h2>

          <p class={classes.detailsParagraph}>
            You may provide us with personal data when you interact with our
            website. For example, when you contact us or request information,
            you will be asked to provide your name, work email address, company
            name, and phone. We will need your name, company name, and work
            email to contact you.
          </p>

          <h2>Log Files</h2>

          <p class={classes.detailsParagraph}>
            Forged Concepts follows a standard procedure of using log files.
            These files log visitors when they visit websites. All hosting
            companies do this and a part of hosting services' analytics. The
            information collected by log files include internet protocol (IP)
            addresses, browser type, Internet Service Provider (ISP), date and
            time stamp, referring/exit pages, and possibly the number of clicks.
            These are not linked to any information that is personally
            identifiable. The purpose of the information is for analyzing
            trends, administering the site, tracking users' movement on the
            website, and gathering demographic information.
          </p>

          <h2>Cookies and Web Beacons</h2>

          <p class={classes.detailsParagraph}>
            Like any other website, Forged Concepts uses 'cookies'. These
            cookies are used to store information including visitors'
            preferences, and the pages on the website that the visitor accessed
            or visited. The information is used to optimize the users'
            experience by customizing our web page content based on visitors'
            browser type and/or other information.
          </p>

          <p class={classes.detailsParagraph}>
            Cookies are a small text file (up to 4KB) created by a website that
            is stored in the user's computer either temporarily for that session
            only or permanently on the hard disk (persistent cookie). Cookies
            provide a way for the website to recognize you and keep track of
            your preferences. You can control the use of cookies by selecting
            appropriate settings in your browser. If you choose to disable
            cookies, it may limit your use of certain functions on the Website.
          </p>

          <h2>Privacy Policies</h2>

          <p class={classes.detailsParagraph}>
            You may consult this list to find the Privacy Policy for each of the
            advertising partners of Forged Concepts.
          </p>

          <p class={classes.detailsParagraph}>
            Third-party ad servers or ad networks uses technologies like
            cookies, JavaScript, or Web Beacons that are used in their
            respective advertisements and links that appear on Forged Concepts,
            which are sent directly to users' browser. They automatically
            receive your IP address when this occurs. These technologies are
            used to measure the effectiveness of their advertising campaigns
            and/or to personalize the advertising content that you see on
            websites that you visit.
          </p>

          <p class={classes.detailsParagraph}>
            Note that Forged Concepts has no access to or control over these
            cookies that are used by third-party advertisers.
          </p>

          <h2>Third Party Privacy Policies</h2>

          <p class={classes.detailsParagraph}>
            Forged Concepts's Privacy Policy does not apply to other advertisers
            or websites. Thus, we are advising you to consult the respective
            Privacy Policies of these third-party ad servers for more detailed
            information. It may include their practices and instructions about
            how to opt-out of certain options.
          </p>

          <p class={classes.detailsParagraph}>
            You can choose to disable cookies through your individual browser
            options. To know more detailed information about cookie management
            with specific web browsers, it can be found at the browsers'
            respective websites. What Are Cookies?
          </p>

          <h2>Information We Collect From Third Party Sources</h2>

          <p class={classes.detailsParagraph}>
            e collect information from third party sources including Google,
            Amazon, and Sendgrid regarding website usage and traffic, online
            chat, and email communication.
          </p>

          <h2>Children's Information</h2>

          <p class={classes.detailsParagraph}>
            Another part of our priority is adding protection for children while
            using the internet. We encourage parents and guardians to observe,
            participate in, and/or monitor and guide their online activity.
          </p>

          <p class={classes.detailsParagraph}>
            Forged Concepts does not knowingly collect any Personal Identifiable
            Information from children under the age of 13. If you think that
            your child provided this kind of information on our website, we
            strongly encourage you to contact us immediately and we will do our
            best efforts to promptly remove such information from our records.
          </p>

          <h2>Online Privacy Policy Only</h2>

          <p class={classes.detailsParagraph}>
            This Privacy Policy applies only to our online activities and is
            valid for visitors to our website with regards to the information
            that they shared and/or collect in Forged Concepts. This policy is
            not applicable to any information collected offline or via channels
            other than this website.
          </p>

          <h2>Consent</h2>

          <p class={classes.detailsParagraph}>
            By using our website, you hereby consent to our Privacy Policy and
            agree to its Terms and Conditions.
          </p>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DetailsSection;
