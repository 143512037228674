import React from "react";
import Grid from "@material-ui/core/Grid";
import GearIcon from "../../../assets/images/icons/gear.svg";
import SecurityIcon from "../../../assets/images/icons/security.svg";
import SearchIcon from "../../../assets/images/icons/search.svg";
import DevOpsLoop from "../../../assets/images/icons/devopsloop.svg";
import useStyles from "../styles";

const DetailsSection = () => {
  const classes = useStyles();
  return (
    <Grid className={classes.detailsContainer} direction="column" container>
      <Grid
        className={`${classes.detailsRows} ${classes.detailsFirstRow}`}
        container
        direction="column"
        justify="center"
      >
        <Grid container alignItems="center" justify="flex-start">
          <h2 className={classes.detailsHeaderTitleText}>The DevOps Process</h2>
        </Grid>
        <Grid container alignItems="center" justify="center">
          <div>
            <div className={classes.devOpsWordsContainer}>
              <h2 className={`${classes.word} ${classes.devWord}`}>Dev</h2>
              <h2 className={`${classes.word} ${classes.opsWord}`}>Ops</h2>
            </div>
            <img
              className={classes.devOpsLoopImage}
              src={DevOpsLoop}
              alt="Cloud Infrastructure"
            />
          </div>
        </Grid>
        <p className={classes.firstSectionDetailsText}>
          {">"} DevOps is a set of practices that combines software development
          and operations. By combining these two, you can decrease your
          development life cycle and provide continuous delivery.
        </p>
        <p className={classes.firstSectionDetailsText}>
          {">"} Here at Forged Concepts, we use best practices and tools to
          increase your organization's ability to deliver applications and
          services faster and without losing quality. We believe DevOps should
          be a part of your development process and we want to help you
          integrate it. This doesn't mean a cookie-cutter approach but creating
          a unique approach that uses best practices tailored to your needs.
        </p>
      </Grid>
      <Grid
        className={`${classes.detailsRows} ${classes.detailsMiddleRow}`}
        container
        direction="row"
      >
        <Grid
          item
          className={`${classes.detailsColumn} ${classes.leftColumn}`}
          container
          direction="column"
          alignItems="center"
          justify="flex-start"
          xs={12}
          md={6}
        >
          <img
            className={classes.appModIcon}
            src={GearIcon}
            alt="Cloud Infrastructure"
          />
          <h2 className={classes.detailsHeaderTitleText}>Automation</h2>
          <p className={classes.columnDetailsText}>
            {">"} Forged Concepts can help you automate your application,
            infrastructure builds, and deployment using best practices and tools
            will help free up resources and time for your development team, so
            you can focus on what you do best, building your application and
            services.
          </p>
        </Grid>
        <Grid
          item
          className={`${classes.detailsColumn} ${classes.rightColumn}`}
          container
          direction="column"
          alignItems="center"
          justify="flex-start"
          xs={12}
          md={6}
        >
          <img className={classes.appModIcon} src={SearchIcon} alt="DevOps" />
          <h2 className={classes.detailsHeaderTitleText}>Monitoring</h2>
          <p className={classes.columnDetailsText}>
            {">"} Forged Concepts can help set up and maintain monitoring for
            your application and infrastructure to support the highest
            availability. We do this by utilizing cloud-managed monitoring that
            will help bring near real-time information to know the health of
            your application and infrastructure.
          </p>
        </Grid>
      </Grid>
      <Grid
        className={`${classes.detailsRows} ${classes.detailsLastRow}`}
        container
        direction="row"
        alignItems="center"
        justify="center"
      >
        <Grid
          item
          xs={12}
          md={12}
          container
          direction="column"
          alignItems="center"
        >
          <img
            className={classes.appModIcon}
            src={SecurityIcon}
            alt="Cloud Infrastructure"
          />
          <h2 className={classes.detailsHeaderTitleText}>Security</h2>
        </Grid>
        <Grid
          item
          container
          className={classes.detailsText}
          justify="center"
          alignItems="center"
          xs={12}
          md={12}
        >
          <p className={classes.detailsText}>
            {">"} From the beginning of every project, Forged Concepts has
            security in mind to help keep your data secure.
          </p>
          <p className={classes.detailsText}>
            {">"} Whether in transit or at rest, we aid with encrypting your
            data end-to-end. This is done by leveraging cloud architecture built
            for the most security-sensitive organizations.
          </p>
          <p className={classes.detailsText}>
            {">"} Security requirements are not always cook-cutter. Forged
            Concepts evaluates your requirements to support your company
            security compliance.
          </p>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DetailsSection;
