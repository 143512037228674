import React from "react";
import Grid from "@material-ui/core/Grid";
import useStyles from "../styles";

const ServicesSection = () => {
  const classes = useStyles();
  return (
    <Grid
      className={`${classes.container} ${classes.servicesBackground}`}
      container
      direction="row"
      justify="flex-start"
      alignItems="center"
    >
      <Grid
        item
        className={classes.containerSection}
        justify="flex-start"
        alignItems="center"
        container
        xs={12}
        sm={6}
        md={6}
      >
        <h2 className={classes.servicesTitleText}>SERVICES</h2>
      </Grid>
      <Grid
        item
        className={`${classes.containerSection} ${classes.servicesContainerSection}`}
        container
        xs={12}
        sm={6}
        md={6}
      >
        <Grid container className={classes.servicesDetailsContainer}></Grid>
      </Grid>
    </Grid>
  );
};

export default ServicesSection;
