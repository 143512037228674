import { createContext, useContext, useReducer } from "react";
import { Container, Grid } from "@material-ui/core";

import Footer from "../components/Footer";
import Header from "../components/Header";
import SideBarDrawer from "../components/SideBarDrawer";

const initialState = {
  open: false,
};

const LayoutStateContext = createContext();
const LayoutDispatchContext = createContext();

const layoutReducer = (state, action) => {
  switch (action?.type) {
    case "DRAWER": {
      return {
        ...state,
        open: action.open,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

const LayoutProvider = ({ children }) => {
  let [state, dispatch] = useReducer(layoutReducer, initialState);

  const gridStyle = {
    backgroundColor: "#fff",
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Container style={gridStyle} disableGutters>
          <LayoutStateContext.Provider value={state}>
            <LayoutDispatchContext.Provider value={dispatch}>
              <SideBarDrawer />
              <Header />
              {children}
              <Footer />
            </LayoutDispatchContext.Provider>
          </LayoutStateContext.Provider>
        </Container>
      </Grid>
    </Grid>
  );
};

const useLayoutState = () => {
  let context = useContext(LayoutStateContext);
  if (context === undefined) {
    throw new Error("getRegionState must be used within a LayoutProvider");
  }
  return context;
};

const useLayoutDispatch = () => {
  let context = useContext(LayoutDispatchContext);
  if (context === undefined) {
    throw new Error("getRegionsDispatch must be used within a LayoutProvider");
  }
  return context;
};

const updateDrawer = (dispatch, open) => {
  dispatch({
    type: "DRAWER",
    open,
  });
};

export { LayoutProvider, useLayoutState, useLayoutDispatch, updateDrawer };
