import { makeStyles } from "@material-ui/core";
import BannerBg from "../../../assets/images/background/banner_bg.png";

export default makeStyles((theme) => ({
  contactFormContainer: {
    backgroundColor: "#fff",
    backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, .9), rgba(255, 255, 255, .5)), url(${BannerBg})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    [theme.breakpoints.down("xs")]: {
      padding: "2em",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "4em",
    },
  },
  contactFormHeading: {
    color: "#6D6E71",
    fontSize: "3em",
    margin: 0,
  },
  contactFormSubHeading: {
    margin: 0,
  },
  contactFormMessage: {
    marginLeft: "1em",
  },
  // TextField
  textFieldContainer: {
    display: "flex",
    flexDirection: "column",
    padding: ".5em 1em",
  },
  textFieldLabel: {
    fontSize: "1.5em",
  },
  textFieldInput: {
    border: "none",
    height: "3em",
    fontSize: "1em",
    padding: "0.5em",
  },

  // TextArea
  textAreaContainer: {
    display: "flex",
    flexDirection: "column",
    padding: ".5em 1em",
  },
  textAreaLabel: {
    fontSize: "1.5em",
  },
  textAreaTextArea: {
    border: "none",
    height: "10em",
    padding: "0.5em",
    fontSize: "1.25em",
  },

  // Agreement section
  agreementCommContainer: {
    padding: ".5em 1em 0 1em",
  },

  // CheckBox
  checkBoxContainer: {
    display: "flex",
    flexDirection: "row",
    padding: ".5em",
  },
  checkBoxCheckBox: {
    border: "none",
    [theme.breakpoints.up("sm")]: {
      height: "3em",
      width: "3em",
    },
    [theme.breakpoints.down("xs")]: {
      height: "6em",
      margin: 0,
      marginRight: ".5em",
      width: "6em",
    },
  },
  checkBoxLabel: {
    fontSize: "1.2em",
  },

  // Contact us button
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    padding: ".5em",
  },
  contactUsButton: {
    border: "none",
    fontSize: "1em",
    fontWeight: "bold",
    padding: ".75em",
    textAlign: "center",
    textDecoration: "none",
  },
}));
