import React from "react";
import Grid from "@material-ui/core/Grid";
import useStyles from "./styles";

const HomeNotesSection = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      className={classes.homeNotesSection}
    >
      <p>
        {">"} If you have a great idea, but you need some assistance with
        developing it, Forged Concepts is here to help. We are cloud experts,
        and we know how to build applications for the cloud. We also help build
        applications that are event-driven, so you don’t have to pay for idle
        time, but can still scale as your application grows.
      </p>
      <p>
        {">"} Here at Forged Concepts, we use best practices and tools to
        increase your organization’s ability to deliver applications and
        services faster without losing quality. Further, we believe DevOps
        should be integrated with your development plan and we want to help
        accomplish that. This doesn’t mean a cookie-cutter approach, but instead
        creating a unique approach that uses best practices for your needs.
      </p>
    </Grid>
  );
};

export default HomeNotesSection;
