import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  contentContainer: {
    width: "100%",
    height: "100%",
    "& p": {
      textShadow: "0px 0px 0.5px black;",
      lineHeight: "1.4em",
      fontSize: "1.2em",
    },
  },
  container: {
    height: "75vh",
    flexDirection: "row",
    marginBottom: "3px",
    [theme.breakpoints.down("md")]: {
      height: "35vh",
    },
    [theme.breakpoints.down("sm")]: {
      height: "30vh",
    },
    [theme.breakpoints.down("xs")]: {
      height: "25vh",
    },
  },
  containerSection: {
    width: "100%",
    height: "100%",
  },
  bannerColumn: {
    width: "50%",
    height: "100%",
  },
  appModIcon: {
    width: 50,
    height: 50,
    padding: 8,
  },
  devOpsIcon: {
    width: 65,
    height: 65,
    padding: 8,
    border: "3px solid #92278f",
    borderRadius: 12,
    [theme.breakpoints.down("md")]: {
      width: 45,
      height: 45,
      padding: 5,
      border: "1px solid #92278f",
      borderRadius: 5,
    },
    [theme.breakpoints.down("sm")]: {
      width: 35,
      height: 35,
      padding: 8,
      border: "1px solid #92278f",
      borderRadius: 10,
    },
    [theme.breakpoints.down("xs")]: {
      width: 20,
      height: 20,
      padding: 5,
      border: "1px solid #92278f",
      borderRadius: 5,
    },
  },
  bannerIcon: {
    width: 75,
    height: 75,
    [theme.breakpoints.down("md")]: {
      width: 45,
      height: 45,
    },
    [theme.breakpoints.down("sm")]: {
      width: 35,
      height: 35,
    },
    [theme.breakpoints.down("xs")]: {
      width: 20,
      height: 20,
    },
  },
  sectionTitleText: {
    fontSize: "3em",
    color: "white",
    width: "50%",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: "3em",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2em",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5em",
    },
  },
  detailsContainer: {
    height: "100%",
  },
  detailsRows: {
    backgroundColor: "#f4e9f4ff",
    height: "20%",
    padding: "0em 7em",
    marginBottom: "3px",
    [theme.breakpoints.down("xs")]: {
      padding: "0em 3em",
    },
  },
  detailsMiddleRow: {
    padding: "0em",
    height: "50%",
    backgroundColor: "rgba(222, 223, 224, 0)",
  },
  detailsColumn: {
    width: "49.8%",
    height: "auto",
    padding: "2em 0em",
    backgroundColor: "#dedfe0ff",
  },
  leftColumn: {
    borderRight: "1.5px solid white",
    [theme.breakpoints.down("xs")]: {
      borderRight: "0px",
    },
  },
  rightColumn: {
    borderLeft: "1.5px solid white",
    [theme.breakpoints.down("xs")]: {
      borderLeft: "0px",
      borderTop: "3px solid white",
    },
  },
  detailsFirstRow: {
    paddingBottom: "5em",
    paddingTop: "5em",
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "3em",
      paddingTop: "3em",
    },
  },
  detailsLastRow: {
    height: "auto",
    padding: "2em 0em",
    margin: "0em",
    backgroundColor: "#dedfe0ff",
  },
  detailsHeaderTitleText: {
    paddingBottom: "0.75em",
    fontSize: "2em",
    color: "grey",
    margin: 0,
  },
  detailsText: {
    margin: 0,
    fontSize: "1em",
    width: "90%",
    [theme.breakpoints.down("xs")]: {
      width: "75%",
    },
    paddingBottom: "1.75em",
  },
  columnDetailsText: {
    margin: 0,
    fontSize: "1em",
    width: "70%",
    paddingBottom: "5em",
  },
  detailsTextContainer: {
    paddingBottom: "1em 0em",
  },
  firstSectionDetailsText: {
    margin: 0,
    fontSize: "1.1em",
    padding: "1em 0em",
  },
  devOpsLoopImage: {
    width: "45em",
    height: "25em",
    [theme.breakpoints.down("xs")]: {
      width: "16.5em",
      height: "10em",
    },
    [theme.breakpoints.only("sm")]: {
      width: "35em",
      height: "25em",
    },
    [theme.breakpoints.up("md")]: {
      width: "45em",
      height: "25em",
    },
  },
  devOpsWordsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    width: "45em",
    height: "25em",
    [theme.breakpoints.down("xs")]: {
      width: "16.5em",
      height: "10em",
    },
    [theme.breakpoints.only("sm")]: {
      width: "35em",
      height: "25em",
    },
    [theme.breakpoints.up("md")]: {
      width: "45em",
      height: "25em",
    },
  },
  word: {
    height: "auto",
    fontSize: "3em",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5em",
    },
  },
  devWord: {
    [theme.breakpoints.down("xs")]: {
      marginRight: "1em",
    },
    [theme.breakpoints.only("sm")]: {
      marginRight: "1em",
    },
    [theme.breakpoints.up("md")]: {
      marginRight: "3em",
    },
  },
  opsWord: {
    marginLeft: "3em",
  },
}));
