import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  contentContainer: {
    width: "100%",
    height: "100%",
  },
  container: {
    flexDirection: "row",
    marginBottom: "3px",
    minHeight: '30em',
  },
  containerSection: {
    width: "100%",
    minHeight: '30em',
  },
  bannerColumn: {
    width: "50%",
    height: "100%",
  },
  appModIcon: {
    marginBottom: 25,
    width: 65,
    height: 65,
    padding: 8,
  },
  sectionTitleText: {
    fontSize: "3em",
    color: "white",
    width: "50%",
    textAlign: "center",
    margin: 0
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    margin: "2.5em 0em",
    [theme.breakpoints.down("md")]: {
      margin: "2em 0em",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "1.5em 0em",
    },
  },
  section: {
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "75vh",
    marginBottom: "3px",
  },
  notesSection: {
    height: "auto",
    backgroundColor: "#dedfe0",
    padding: "0em 5em",
    [theme.breakpoints.down("md")]: {
      padding: "0em 3em",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0em 2em",
    },
  },
  notesSubHeadingText: {
    fontSize: "2em",
    color: "grey",
    marginLeft: '0.25em',
    [theme.breakpoints.down("md")]: {
      fontSize: "1.75em",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5em",
    },
  },
  note: {
    fontSize: "1.25em",
    margin: 0,
    marginBottom: "2.5em",
    [theme.breakpoints.down("md")]: {
      fontSize: "1em",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1em",
    },
  },
}));
