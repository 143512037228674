import React from "react";
import { useHistory } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import { NavLink as Link } from "react-router-dom";
import { NavHashLink } from 'react-router-hash-link';
import routes from "../../utils/Routes";
import {
  useLayoutState,
  useLayoutDispatch,
  updateDrawer,
} from "../../context/layoutContext";
import useStyles from "./styles";

const SideBarDrawer = () => {
  const classes = useStyles();
  const dispatch = useLayoutDispatch();
  const { open } = useLayoutState();

  const history = useHistory();

  const navigate = (event, route) => {
    event.preventDefault();
    history.push(route);
    updateDrawer(dispatch, !open);
  };

  const navBarLink = {
    textDecoration: "none",
    color: "white",
  };

  const navBarActiveLink = {
    fontWeight: "bold",
    textDecoration: "underline",
  };

  const contactUsLink = {
    fontWeight: "normal",
    textDecoration: "none",
  }

  return (
    <Drawer
      classes={{
        paper: classes.container,
      }}
      anchor="right"
      open={open}
      onClose={() => {
        updateDrawer(dispatch, !open);
      }}
    >
      <IconButton
        classes={{
          root: classes.crossButton,
        }}
        color="secondary"
        aria-label="upload picture"
        component="span"
        onClick={() => {
          updateDrawer(dispatch, !open);
        }}
      >
        <CloseIcon />
      </IconButton>
      <Grid
        className={classes.menuItemsContainer}
        container
        direction="column"
        justify="space-between"
        alignItems="flex-start"
      >
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="flex-start"
        >
          {routes.map((route) => (
            <Link
              key={route.name}
              to={route.path}
              exact
              style={navBarLink}
              activeStyle={navBarActiveLink}
              onClick={(e) => navigate(e, route.path)}
            >
              <h6 className={classes.menuItem}>{route.name}</h6>
            </Link>
          ))}
          <NavHashLink
            smooth
            key="contact_us"
            to="/#contact_us"
            exact
            style={navBarLink}
            activeStyle={contactUsLink}
            onClick={(e) => navigate(e, "/#contact_us")}
          >
            <h6 className={classes.menuItem}>Contact Us</h6>
          </NavHashLink>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default SideBarDrawer;
