import React from "react";
import Grid from "@material-ui/core/Grid";

import routes from "../../utils/Routes";
import Section from "./Components/Section";
import ServicesSection from "./Components/ServicesSection";

const Services = () => {
  return (
    <Grid container direction="column">
      <ServicesSection />
      {routes
        .filter((route) => route.isService)
        .map((route) => (
          <Section
            title={route.name}
            key={route.path}
            backgroundImage={`url(${route.background})`}
            appModIcon={route.icon}
            backgroundColor={route.backgroundColor}
            position={route.position}
            path={route.path}
          />
        ))}
    </Grid>
  );
};

export default Services;
