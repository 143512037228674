import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  contentContainer: {
    width: '100%',
    height: '100%',
  },
  container: {
    height: '75vh',
    flexDirection: 'row',
    marginBottom: '3px',
  },
  containerSection: {
    width: '100%',
    height: '100%',
  },
  bannerColumn: {
    width: '50%',
    height: '100%',
  },
  appModIcon: {
    width: 40,
    height: 40,
    padding: 8,
  },
  bannerIcon: {
    width: 75,
    height: 75,
    marginBottom: 25,
  },
  sectionTitleText: {
    fontSize: '3em',
    color: 'white',
    width: '50%',
    textAlign: 'center',
  },
  section: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '75vh',
    marginBottom: '3px',
  },
  notesSection: {
    height: '50vh',
    backgroundColor: '#dedfe0',
    padding: '0em 5em'
  },
  notesSubHeadingText: {
    fontSize: '1.7em',
    margin: '2.5em 0em',
    color: 'grey',
  },
  note: {
    fontSize: '0.85em',
    margin: 0,
    marginBottom: '2.5em'
  },
  detailsContainer: {
    height: 'auto'
  },
  detailsRows: {
    backgroundColor: '#29ade019',
    height: '20%',
    padding: '0em 7em',
    marginBottom: '3px',
    [theme.breakpoints.down("sm")]: {
      padding: '0em 2em',
    },
  },
  detailsMiddleRow: {
    padding: '0em',
    height: '45%'
  },
  detailsColumn: {
    width: '50%',
    height: '100%',
    padding: '2em 0em'
  },
  columnTopDivider: {
    [theme.breakpoints.down("sm")]: {
      borderTop: '3px solid white'
    },
  },
  detailsLastRow: {
    height: '34%',
    paddingTop: '2.5em',
    margin: '0em',
  },
  detailsHeaderTitleText: {
    padding: '0.5em 0em',
    fontSize: '2em',
    color: 'grey',
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      fontSize: '1.5em',
    },
  },
  tagHeaderText: {
    padding: '2em 0em',
    fontSize: '2em',
    color: 'grey',
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      fontSize: '1.5em',
    },
  },
  detailsText: {
    padding: '3em',
    margin: 0,
    fontSize: '1.25em',
    [theme.breakpoints.down("sm")]: {
      fontSize: '0.85em',
      padding: '1.5em',
    },
  }
}))