import { Grid, Hidden, IconButton } from '@material-ui/core';
import {
  updateDrawer,
  useLayoutDispatch,
  useLayoutState,
} from '../../context/layoutContext';

import { NavLink as Link } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import { NavHashLink } from 'react-router-hash-link';
import React from 'react';
import logo from '../../assets/images/icons/logo.svg';
import { useHistory } from 'react-router-dom';
import useStyles from './styles';

const Header = () => {
  const dispatch = useLayoutDispatch();
  const { open } = useLayoutState();
  const classes = useStyles();
  const history = useHistory();

  const navigate = (event, route) => {
    event.preventDefault();
    history.push(route);
  };

  const navBarLink = {
    textDecoration: 'none',
    color: 'black',
  };

  const navBarActiveLink = {
    fontWeight: 'bold',
    textDecoration: 'underline',
  };

  const contactUsLink = {
    fontWeight: 'normal',
    textDecoration: 'none',
  };

  return (
    <Grid
      container
      className={classes.container}
      justify="space-between"
      alignItems="center"
    >
      <Grid item onClick={(e) => navigate(e, '/')}>
        <div className={classes.logoContainer}>
          <img src={logo} className={classes.logo} alt="logo" />
          <div className={classes.logoTextContainer}>
            <h3 className={classes.logoForgedText}>FORGED</h3>
            <div>
              <h3 className={classes.logoConceptsText}>CONCEPTS</h3>
              <span
                className={classes.logoTMText}
                dangerouslySetInnerHTML={{ __html: '&reg;' }}
              />
            </div>
          </div>
        </div>
      </Grid>
      <Grid item>
        <ul className={classes.navbar}>
          <Hidden xsDown>
            <li className={classes.navbarItem}>
              <Link
                to="/"
                exact={true}
                style={navBarLink}
                activeStyle={navBarActiveLink}
              >
                HOME
              </Link>
            </li>
            <li className={`${classes.navbarDivider} ${classes.navbarItem}`}>
              |
            </li>
            <li className={classes.navbarItem}>
              <Link
                to="/services"
                exact={true}
                style={navBarLink}
                activeStyle={navBarActiveLink}
              >
                SERVICES
              </Link>
            </li>
            <li className={`${classes.navbarDivider} ${classes.navbarItem}`}>
              |
            </li>
            <li className={classes.navbarItem}>
              <NavHashLink
                smooth
                to="/#contact_us"
                exact={true}
                style={navBarLink}
                activeStyle={contactUsLink}
              >
                CONTACT US
              </NavHashLink>
            </li>
          </Hidden>
          <li className={`${classes.navbarItem} ${classes.navBarItemIcon}`}>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              className={classes.navbarButton}
              onClick={() => updateDrawer(dispatch, !open)}
            >
              <MenuIcon className={classes.navbarButtonInner} />
            </IconButton>
          </li>
        </ul>
      </Grid>
    </Grid>
  );
};

export default Header;
