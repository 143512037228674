import React from "react";
import { useHistory } from "react-router-dom";

import routes from "../../../utils/Routes";
import { Grid, Link } from "@material-ui/core";
import PopArrow from "../../../assets/images/icons/drop_pop_arrow.svg";
import useStyles from "../styles";

const BannerSection = () => {
  const classes = useStyles();

  const history = useHistory();

  const navigate = (event, route) => {
    event.preventDefault();
    history.push(route);
  };

  return (
    <Grid container className={classes.bannerSectionContainer}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={6}
          className={classes.bannerSectionHeadingContainer}
        >
          <h1 className={classes.bannerSectionHeading}>
            BUILDING
            <br />
            GREAT IDEAS
          </h1>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className={classes.bannerSectionLinksContainer}
        >
          <Grid container className={classes.bannerSectionLinksContainerHolder}>
            <Grid item xs={1}></Grid>
            <Grid
              item
              xs={10}
              className={classes.bannerSectionServicesContainer}
            >
              <div className={classes.bannerSectionLinkContainer}>
                {routes
                  .filter((route) => route.isService)
                  .map((route) => (
                    <div
                      className={classes.bannerSectionLinkContainerItem}
                      key={route.name}
                    >
                      <img src={PopArrow} width={30} height={30} alt="->" />
                      <Link
                        className={classes.bannerSectionLink}
                        href="#"
                        onClick={(e) => navigate(e, route.path)}
                      >
                        {route.name}
                      </Link>
                    </div>
                  ))}
              </div>
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BannerSection;
