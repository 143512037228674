import React from "react";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import { useHistory } from "react-router-dom";
import useStyles from "../styles";

const Section = ({
  title,
  appModIcon,
  backgroundImage,
  backgroundColor,
  position = "start",
  path,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const backgroundImageStyle = {
    backgroundImage,
  };

  const backgroundColorStyle = {
    backgroundColor,
  };

  const navigate = (event, route) => {
    event.preventDefault();
    history.push(route);
  };

  return (
    <Grid
      className={`${classes.container} ${classes.background}`}
      style={backgroundImageStyle}
      container
      direction="row"
      justify="flex-start"
      alignItems="center"
    >
      {position === "start" ? (
        <>
          <Grid
            item
            onClick={(e) => navigate(e, path)}
            className={`${classes.containerSection} ${classes.navigationSectionHover}`}
            style={backgroundColorStyle}
            justify="center"
            alignItems="center"
            direction="column"
            container
            xs={12}
            sm={6}
            md={6}
          >
            <img className={classes.appModIcon} src={appModIcon} alt="DevOps" />
            <h2 className={classes.sectionTitleText}>{title}</h2>
          </Grid>
          <Hidden xsDown>
            <Grid className={classes.containerSection} item></Grid>
          </Hidden>
        </>
      ) : (
        <>
          <Hidden xsDown>
            <Grid className={classes.containerSection} item></Grid>
          </Hidden>
          <Grid
            item
            onClick={(e) => navigate(e, path)}
            className={`${classes.containerSection} ${classes.navigationSectionHover}`}
            style={backgroundColorStyle}
            justify="center"
            alignItems="center"
            direction="column"
            container
            xs={12}
            sm={6}
            md={6}
          >
            <img className={classes.appModIcon} src={appModIcon} alt="DevOps" />
            <h2 className={classes.sectionTitleText}>{title}</h2>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Section;
