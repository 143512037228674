import React from "react";
import { Grid } from "@material-ui/core";
import useStyles from "./styles";

const ScreenReviewSection = () => {
  const classes = useStyles();

  return <Grid container className={classes.screenReviewSection}></Grid>;
};

export default ScreenReviewSection;
