import React from "react";
import Grid from "@material-ui/core/Grid";
import BrightnessIcon from "../../../assets/images/icons/brightness.svg";
import GearsWindowIcon from "../../../assets/images/icons/gears_window.svg";
import SheildIcon from "../../../assets/images/icons/shield.svg";
import useStyles from "../styles";

const DetailsSection = () => {
  const classes = useStyles();
  return (
    <Grid className={classes.detailsContainer} direction="column" container>
      <Grid
        className={classes.detailsRows}
        container
        direction="row"
        alignItems="center"
        justify="flex-start"
      >
        <h2
          className={`${classes.detailsHeaderTitleText} ${classes.firstRowHeaderTitleText}`}
        >
          How We Do Application Modernization
        </h2>
      </Grid>
      <Grid
        className={`${classes.detailsRows} ${classes.detailsMiddleRow}`}
        container
        direction="row"
      >
        <Grid
          item
          className={classes.detailsColumn}
          container
          direction="column"
          alignItems="center"
          justify="flex-start"
          xs={12}
          md={6}
        >
          <img
            className={classes.appModIcon}
            src={BrightnessIcon}
            alt="Cloud Infrastructure"
          />
          <h2 className={classes.detailsHeaderTitleText}>Cloud Native</h2>
          <p className={classes.detailsText}>
            {">"} We modernize your application or service with cloud-native
            services, which can help you save you on costs, increase
            performance, and provide more scalability.
          </p>
        </Grid>
        <Grid
          item
          className={`${classes.detailsColumn} ${classes.columnTopDivider}`}
          container
          direction="column"
          alignItems="center"
          justify="flex-start"
          xs={12}
          md={6}
        >
          <img
            className={classes.appModIcon}
            src={GearsWindowIcon}
            alt="DevOps"
          />
          <h2 className={classes.detailsHeaderTitleText}>
            Monitoring and Logging
          </h2>
          <p className={classes.detailsText}>
            {">"} Using cloud-managed services, Forged Concepts can increase
            your visibility. Our cloud-managed services improve monitoring,
            logging, and sets proper alerting to multiple sources that keep
            teams informed.
          </p>
        </Grid>
      </Grid>
      <Grid
        className={`${classes.detailsRows} ${classes.detailsLastRow}`}
        container
        direction="column"
        alignItems="center"
        justify="center"
      >
        <img
          className={classes.appModIcon}
          src={SheildIcon}
          alt="Cloud Infrastructure"
        />
        <h2 className={classes.detailsHeaderTitleText}>Security-focused</h2>
        <p className={classes.detailsText}>
          {">"} Using best practices and tools, we can increase the security of
          your infrastructure, so your data is better protected.
        </p>
      </Grid>
    </Grid>
  );
};

export default DetailsSection;
