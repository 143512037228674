import React from "react";
import { Grid } from "@material-ui/core";
import useStyles from "./styles";

const Divider = () => {
  const classes = useStyles();

  return <Grid container className={classes.divider}></Grid>;
};

export default Divider;
