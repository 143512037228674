import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  contentContainer: {
    width: "100%",
    height: "100%",
  },
  container: {
    minHeight: '30em',
    flexDirection: 'row',
    marginBottom: '3px',
  },
  containerSection: {
    width: '100%',
    minHeight: '30em',
  },
  background: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  bannerColumn: {
    width: '50%',
    height: '100%',
  },
  appModIcon: {
    width: 40,
    height: 40,
    padding: 8,
  },
  bannerIcon: {
    width: 75,
    height: 75,
    marginBottom: 25,
    [theme.breakpoints.down("sm")]: {
      width: 50,
      height: 50,
    },
  },
  sectionTitleText: {
    fontSize: '3em',
    color: 'white',
    width: '50%',
    textAlign: 'center',
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      fontSize: '2em',
      width: '75%',
    },
  },
}));
