import { makeStyles } from "@material-ui/core";
import ServicesBg from "../../assets/images/background/services_bg.png";

export default makeStyles((theme) => ({
  contentContainer: {
    width: "100%",
    height: "75vh",
    [theme.breakpoints.down("md")]: {
      height: "35vh",
    },
    [theme.breakpoints.down("sm")]: {
      height: "30vh",
    },
    [theme.breakpoints.down("xs")]: {
      height: "25vh",
    },
  },
  servicesBackground: {
    backgroundImage: `url(${ServicesBg})`,
    filter: "grayscale(100%)",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  devOpsIcon: {
    border: "3px solid #92278f",
    borderRadius: 12,
    [theme.breakpoints.down("md")]: {
      border: "1px solid #92278f",
      borderRadius: 3,
    },
    [theme.breakpoints.down("sm")]: {
      border: "1px solid #92278f",
      borderRadius: 3,
    },
    [theme.breakpoints.down("xs")]: {
      border: "1px solid #92278f",
      borderRadius: 3,
    },
  },
  sectionTitleText: {
    fontSize: "3em",
    color: "white",
    width: "50%",
    textAlign: "center",
    marginTop: 0,
    [theme.breakpoints.down("md")]: {
      fontSize: "2.5em",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2em",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5em",
    },
  },
  container: {
    minHeight: "30em",
    flexDirection: "row",
    marginBottom: "3px",
    [theme.breakpoints.down("md")]: {
      minHeight: "30em",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "20em",
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: "15em",
    },
  },
  containerSection: {
    minHeight: "30em",
    width: "50%",
    [theme.breakpoints.down("md")]: {
      minHeight: "30em",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "20em",
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: "15em",
    },
  },
  servicesTitleText: {
    fontSize: "5em",
    color: "white",
    marginLeft: theme.spacing(15),
    [theme.breakpoints.down("md")]: {
      fontSize: "3em",
      marginLeft: theme.spacing(10),
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.5em",
      marginLeft: theme.spacing(7),
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.5em",
      marginLeft: theme.spacing(5),
    },
  },
  servicesContainerSection: {
    paddingRight: "5%",
    [theme.breakpoints.down("xs")]: {
      paddingRight: 0,
    },
  },
  servicesDetailsContainer: {
    height: "80%",
    backgroundColor: "rgba(0,0,0,0.75)",
    padding: "3em",
    [theme.breakpoints.down("md")]: {
      padding: "3em",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "3em",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "1em",
      height: 'auto'
    },
  },
  servicesDetailsText: {
    color: "white",
    textAlign: "left",
    fontWeight: "bold",
    fontSize: "1.15em",
    [theme.breakpoints.down("md")]: {
      fontSize: "1em",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.725em",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.75em",
    },
  },
  background: {
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  appModIcon: {
    marginBottom: 25,
    width: 65,
    height: 65,
    padding: 8,
    [theme.breakpoints.down("md")]: {
      width: 45,
      height: 45,
      marginBottom: 5,
      padding: 6,
    },
    [theme.breakpoints.down("sm")]: {
      width: 35,
      height: 35,
      marginBottom: 5,
      padding: 5,
    },
    [theme.breakpoints.down("xs")]: {
      width: 35,
      height: 35,
      marginBottom: 5,
      padding: 4,
    },
  },
  navigationSectionHover: {
    '&:hover': {
      opacity: 0.75,
      cursor: 'pointer'
    },
  }
}));