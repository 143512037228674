import React from "react";
import Grid from "@material-ui/core/Grid";
import SocialSection from "./Components/SocialSection";
import OurServicesSection from "./Components/OurServicesSection";
import AboutUsSection from "./Components/AboutUsSection";
import useStyles from "./styles";

const Footer = () => {
  const classes = useStyles();
  return (
    <Grid container className={classes.container}>
      <Grid
        container
        alignItems="stretch"
        justify="center"
        className={classes.overlayContainer}
      >
        <Grid item xs={12} sm={5} className={classes.sectionContainer}>
          <AboutUsSection />
        </Grid>
        <Grid item xs={12} sm={4} className={classes.sectionContainer}>
          <OurServicesSection />
        </Grid>
        <Grid item xs={12} sm={3} className={classes.sectionContainer}>
          <SocialSection />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
